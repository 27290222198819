import { NgIterable, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, debounceTime, map, of, startWith } from 'rxjs';

@Pipe({
  name: 'filterAutocompleteOptions',
})
export class FilterAutocompleteOptionsPipe implements PipeTransform {

  transform(options: any[], formControl: AbstractControl<any, any>): Observable<NgIterable<any>> {
    if (!options) {
      return of(options);
    }
    const _filter = (value: string): string[] => {
      const filterValue = value.toLowerCase();
      return options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    return formControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(value => _filter(value || '')),
    );
  
  }

}
