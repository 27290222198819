import { Component } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';

@Component({
  selector: 'itfg-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  breadcrumbs: any;
  navigationInfo: BreadcrumbsService;

  constructor(navigationInfo: BreadcrumbsService) {
    this.navigationInfo = navigationInfo;
    this.navigationInfo.breadcrumbsChanged$.subscribe(
      (breadcrumbs: any[]) => {
        this.breadcrumbs = breadcrumbs;
      }
    );
  }

  ngOnInit() {
    this.breadcrumbs = this.navigationInfo.breadcrumbs;
  }
}
