import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'itfg-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  file: File | null = null;
  @Input() isDisabled = false;
  @Input() hidden = false;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter();
  @Input() captureMode = 'environment';
  @ViewChild('fileUpload')
  public fileUpload: any;

  constructor() {}

  triggerUploadButton() {
    if (this.fileUpload?.nativeElement) {
      this.fileUpload.nativeElement.click();
    }
  }

  ngOnInit(): void {}

  compressImageToFitSize(file: File, maxSizeInMb: number = 10, maxAttempts: number = 5, initialQuality: number = 1): Promise<File> {
    const maxSizeInBytes = maxSizeInMb * 1024 * 1024;
    const compress = (file: File, quality: number, attempt: number = 1): Promise<File> => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          const originalAspectRatio = width / height;
          const maxWidth = 3840;
          const maxHeight = 2160;

          // Adjust dimensions while maintaining aspect ratio
          if (width > maxWidth || height > maxHeight) {
              if (width / height > maxWidth / maxHeight) {
                  width = maxWidth;
                  height = width / originalAspectRatio;
              } else {
                  height = maxHeight;
                  width = height * originalAspectRatio;
              }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, image.width, image.height);
  
          canvas.toBlob(blob => {
            if (!blob) {
              reject(new Error('Canvas is empty'));
              return;
            }
            const newFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
  
            if (newFile.size <= maxSizeInBytes || attempt >= maxAttempts) {
              resolve(newFile);
            } else {
              // Attempt to further reduce quality by 10% to meet size requirements
              const newQuality = quality - 0.1;
              compress(file, newQuality, attempt + 1).then(resolve).catch(reject);
            }
          }, 'image/jpeg', quality);
        };
        image.onerror = error => reject(error);
      });
    };
  
    return compress(file, initialQuality);
  }

  onChange(event: any) {
    const file: File = event.target.files[0];
    const fileSizeInMegabytes = file.size / 1024 / 1024;

    if (!file) {
      return;
    }

    if (fileSizeInMegabytes >= 10) {
      this.compressImageToFitSize(file).then(compressedFile => {
        this.file = compressedFile;
        this.fileSelected.emit(compressedFile);
      }).catch(error => {
        console.error("Error compressing the image", error);
      });
    } else {
      this.file = file;
      this.fileSelected.emit(this.file);
    }

  }
}
