<ng-container *ngIf="captureMode === 'environment'">
  <button *ngIf="!hidden" [disabled]="isDisabled" color="primary" mat-raised-button type="button" (click)="$event.stopPropagation(); fileUpload.click();">
    <span>ИЗБЕРИ</span>
    <input id="picture" name="picture" (change)="onChange($event)" #fileUpload type="file" accept="image/*" capture="environment" style="display:none;" readonly/>
  </button>
</ng-container>
<ng-container *ngIf="captureMode === 'user'">
  <button *ngIf="!hidden" [disabled]="isDisabled" color="primary" mat-raised-button type="button" (click)="$event.stopPropagation(); fileUpload.click();">
    <span>ИЗБЕРИ</span>
    <input id="selfie" name="selfie" (change)="onChange($event)" #fileUpload type="file" accept="image/*" capture="user" style="display:none;" readonly/>
  </button>
</ng-container>
<!-- <button *ngIf="!hidden" [disabled]="isDisabled" color="primary" mat-raised-button type="button" (click)="$event.stopPropagation(); fileUpload.click();">
  <span>ИЗБЕРИ</span>
  <input (change)="onChange($event)" #fileUpload type="file" accept="image/*" capture="user" style="display:none;" readonly/>
</button> -->