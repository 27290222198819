<div class="toolbar-container">
  <mat-toolbar color="primary">
    <a mat-button routerLink="/" class="logo-btn">
      <div class="logo-label">
        <img [src]="config.logoUrl" class="logo">
        <ng-container *ngIf="config.logoText">
          <span *ngIf="!enviromentIsProduction">Partners - Staging</span>
          <span *ngIf="enviromentIsProduction">Partners</span>
        </ng-container>
      </div>
    </a>
    <span class="spacer"></span>
    <div *ngIf="auth.isAuthenticated()" class="nav-actions">
      <itfg-nav-split-button></itfg-nav-split-button>
    </div>
    <div *ngIf="!auth.isAuthenticated()">
      <itfg-home-screen-prompt></itfg-home-screen-prompt>
    </div>
  </mat-toolbar>
</div>
<div class="main">
  <div class="subnav">
    <itfg-breadcrumbs></itfg-breadcrumbs>
  </div>
  <div class="outlet-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>