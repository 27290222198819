import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'itfg-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  rawError: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public errorData: any,
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    private router: Router,
  ) {
    this.dialogRef = dialogRef;
    this.errorData = errorData;
    this.rawError = this.errorData.errorMessage;
    console.log(this.errorData.errorMessage);
    this.errorData.errorMessage = JSON.stringify(
      this.errorData.errorMessage,
      null,
      2
    );
  }

  onClose() {
    this.dialogRef.close();
    // this.router.navigate(['/home']);
  }
}
