import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/auth/page-not-found/page-not-found.component';
import { mainGuard } from './components/auth/main.guard';
import { CreditConfirmationPublicViewComponent } from './components/public/credit-confirmation/credit-confirmation-public-view.component';
import { CreditConfirmationPublicViewResolver } from './components/public/credit-confirmation/credit-confirmation-public-view.resolver';
import { PepDisclaimerComponent } from './components/public/pep/pep-disclaimer.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
      },
      {
        path: 'pep',
        component: PepDisclaimerComponent,
        data: {
          titleKey: 'breadcrumbs.pep',
          breadcrumbKey: 'breadcrumbs.pep',
        },
      },
      {
        path: 'credit-confirmation/:creditId/:token',
        component: CreditConfirmationPublicViewComponent,
        resolve: {
          creditConfirmationData: CreditConfirmationPublicViewResolver,
        },
        data: {
          titleKey: 'breadcrumbs.confirmCredit',
          breadcrumbKey: 'breadcrumbs.confirmCredit',
        },
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            m => m.DashboardModule
        ),
        canActivate: [mainGuard],
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./components/profile-page/profile-page.module').then(
            m => m.ProfilePageModule
        ),
        canActivate: [mainGuard],
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./components/application/application.module').then(
            m => m.ApplicationModule
        ),
        canActivate: [mainGuard],
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
