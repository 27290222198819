import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  getItem(itemName: string) {
    return this.storage.getItem(itemName);
  }

  setItem(itemName: string, itemValue: string) {
    return this.storage.setItem(itemName, itemValue);
  }

  removeItem(itemName: string) {
    return this.storage.removeItem(itemName);
  }
}
