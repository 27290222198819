import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { CreditsService } from 'src/app/services/credits.service';
import { ApplicationParsedView } from 'src/app/types/application.type';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export const CreditConfirmationPublicViewResolver: ResolveFn<
  ApplicationParsedView | null
> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  creditsService: CreditsService = inject(CreditsService),
  dialog: MatDialog = inject(MatDialog),
): Observable<any> => {
    const creditId: number | null = Number(route.paramMap.get('creditId'));
    const token: string | null = route.paramMap.get('token');
    return creditsService.fetchApplicationPublic$(creditId, token).pipe(
        tap((application: ApplicationParsedView) => {
            if (!application.raw.credit) {
                dialog.open(InfoDialogComponent, {
                    data: {
                      message: 'Заявката е невалидна.',
                    }
                  });
            }
        }),
        catchError((e) => {
          return of({ exception: e });
        })
      );
};
