import { Product } from "./product-plan.type";
import { UserDetails } from "./user.type";

export enum InvitationStatusType {
    NEW = 'NEW',
    OPENED = 'OPENED',
    COMPLETED = 'COMPLETED'
}

export class Invitation {
    id: number;
    createdAt: Date;
    credit: any;
    description: string;
    email: string;
    partner: UserDetails;
    principal: number;
    product: Product;
    status: {
        id: number;
        name: InvitationStatusType;
    }

    constructor(partial: Partial<Invitation>) {
        Object.assign(this, partial);
    }
}