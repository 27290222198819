import { FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { FloatLabelType } from "@angular/material/form-field";
import { Observable, of } from 'rxjs';

export class CustomFormFieldBase<T> {
  value: T | undefined | number | string;
  key: string;
  label: string;
  placeholder: string;
  required: boolean;
  readonly: boolean;
  preffixIcon: string;
  prefixText: string;
  order: number;
  controlType: string;
  type: string;
  floatLabel: FloatLabelType;
  validators: ValidatorFn[];
  options: { id: string | number, name: string }[];
  optionsBus$: Observable<{ id: string | number, name?: string, type?: string }[]>;
  captureMode?: 'user' | 'environment';
  showValueClearButton?: boolean = true;
  payloadParserCallback?: (value: any) => any;
  trackChangesCallback?: (form: FormGroup, value: any) => any;

  constructor(options: Partial<CustomFormFieldBase<T>> = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.placeholder = options.placeholder || this.label;
    this.floatLabel = options.floatLabel || 'auto';
    this.required = !!options.required;
    this.preffixIcon = options.preffixIcon || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || 'text';
    this.validators = options.validators || [];
    this.options = options.options || [];
    this.showValueClearButton = options.showValueClearButton === undefined ? true : options.showValueClearButton;
    this.optionsBus$ = options.optionsBus$ || of(this.options);
    this.prefixText = options.prefixText || '';
    this.readonly = options.readonly || false;
    if (this.required) {
      this.validators.push(Validators.required);
    }
    this.payloadParserCallback = options.payloadParserCallback;
    this.trackChangesCallback = options.trackChangesCallback;
    this.captureMode = options.captureMode || 'environment';
  }
}