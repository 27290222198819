import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'itfg-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [
    trigger('shake', [
      transition('void => *', [
        animate('0.3s', keyframes([
          style({ transform: 'translateX(0)', offset: 0 }),
          style({ transform: 'translateX(-10px)', offset: 0.2 }),
          style({ transform: 'translateX(10px)', offset: 0.4 }),
          style({ transform: 'translateX(-10px)', offset: 0.6 }),
          style({ transform: 'translateX(10px)', offset: 0.8 }),
          style({ transform: 'translateX(0)', offset: 1 })
        ]))
      ]),
      // transition('* => void', [
      //   animate('0.5s', style({ opacity: 0 }))
      // ])
    ])
  ]
})
export class MessageComponent implements OnChanges, AfterViewInit, AfterViewChecked {
  @ViewChild('messageContainer') messageContainer: ElementRef;

  @Input()
  opened: boolean;

  @Input()
  label: string;

  @Input()
  sublabel: string;

  // TODO: Implement other types
  @Input()
  type: string = 'error';
  
  @Input()
  title: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['opened'] && this.opened) {
      this.scrollToMessage();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (this.opened) {
      this.scrollToMessage();
    }
  }

  ngAfterViewChecked() {
    if (this.opened) {
      this.scrollToMessage();
    }
  }

  private scrollToMessage() {
    console.log(this.messageContainer)
    if (this.messageContainer) {
      const elementPosition = this.messageContainer.nativeElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  }

}
