import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { MatDividerModule } from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SharedModule } from '../shared/shared.module';
import { MfaVerificationComponent } from '../mfa-verification/mfa-verification.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TotpInputComponent } from './totp-input/totp-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MfaInstructionsDialog } from './mfa-instructions-dialog/mfa-instructions-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    DirectivesModule,
    MatDialogModule,
    MatDividerModule,
    MatListModule,
    SharedModule,
    ClipboardModule,
    MatSlideToggleModule,
  ],
  exports: [AuthRoutingModule],
  declarations: [MfaInstructionsDialog, MfaVerificationComponent, TotpInputComponent, AuthComponent, LoginComponent, PageNotFoundComponent, EmailConfirmationComponent, PasswordRecoveryComponent, PasswordResetComponent],
  providers: [],
})
export class AuthModule {}
