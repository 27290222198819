import { Component, Input } from '@angular/core';
import { ApplicationParsedView } from 'src/app/types/application.type';
import { Invitation } from 'src/app/types/invitation.type';

@Component({
  selector: 'itfg-application-status-icon',
  templateUrl: './application-status-icon.component.html',
  styleUrls: ['./application-status-icon.component.scss']
})
export class ApplicationStatusIconComponent {
  @Input()
  application: ApplicationParsedView;

  @Input()
  invitation: Invitation;
}
