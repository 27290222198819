import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'itfg-embedded-notification',
  templateUrl: './embedded-notification.component.html',
  styleUrls: ['./embedded-notification.component.scss']
})
export class EmbeddedNotificationComponent implements OnInit {
  hidden: boolean = true;
  message: string = '';
  action: string = '';

  constructor(
    public snackBarRef: MatSnackBarRef<EmbeddedNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any,
  ) {
  }

  ngOnInit(): void {

  }

  public dismiss() {
    // this.hidden = true;
    this.snackBarRef.dismiss();
  }

}
