import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserDetails } from 'src/app/types/user.type';

export const mainGuard: CanActivateFn = async (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

    if (!authService.currentUser && authService.isAuthenticated()) {
      await authService.loadCurrentUser();
  }

  const roles = authService.currentUser?.roles || [];
  const hasRoles = roles.length > 0;
  const preauthorizationStep = hasRoles && roles.every((role: string) => role === 'PRE_AUTH_PARTNER');

  if (authService.isAuthenticated() && !preauthorizationStep) {
    return true;
  } else {
    if (preauthorizationStep && hasRoles) {
      console.log('navigate to auth/2fa')
      router.navigate(['/auth/2fa']);
    } else {
      console.log('navigate to auth/login')
      router.navigate(['/auth/login']);
    }

    return false;
  }
};
