import { Component, Renderer2 } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'itfg-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss']
})
export class DarkModeToggleComponent {
  isChecked = false;

  constructor(
    private renderer: Renderer2,
    private storage: StorageService,
  ) {
    this.isChecked = !!this.storage.getItem('prefers-color-scheme');
  }

  toggleValueChanged(event: MatSlideToggleChange) {
    if (event.checked) {
      this.storage.setItem('prefers-color-scheme', 'dark');
      this.renderer.addClass(document.body, 'dark-theme');
    } else {
      this.storage.removeItem('prefers-color-scheme');
      this.renderer.removeClass(document.body, 'dark-theme');
    }
  }
}
