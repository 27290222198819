export enum ProductPlanCodes {
  INSTALLMENTS = 'INSTALLMENTS',
  PAYDAY = 'PAYDAY',
  MICRO = 'MICRO',
}

export enum ProductPeriodUnit {
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export interface Product {
  id: number;
  name: string;
  code: ProductPlanCodes;
  apr: number;
  periodUnit: ProductPeriodUnit;
}

export interface ProductPlan {
  id: number;
  product: Product;
  principal: number;
  installmentsNumber: number;
  installmentDays: number;
  dailyInterest: number;
  apr: number;
  installmentAmount: number;
  installmentForfeit: number;
  installmentUtilizationFee: number;
}
