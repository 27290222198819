import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import _moment from 'moment';
import { UserAgentService } from 'src/app/services/user-agent.service';
import { DOMAIN_CONFIG_TOKEN, AppConfig } from 'src/app/config/app.config';

export enum DeviceTypes {
  iOSMobileSafari = 'iOSMobileSafari',
  iosIpadSafari = 'iosIpadSafari',
  AndroidMobile = 'androidMobile',
  DesktopSafari = 'desktopSafari',
  Desktop = 'desktop'
}

@Component({
  selector: 'itfg-home-screen-prompt',
  templateUrl: './home-screen-prompt.component.html',
  styleUrls: ['./home-screen-prompt.component.scss']
})
export class HomeScreenPromptComponent implements OnInit {
  deviceTypes: typeof DeviceTypes = DeviceTypes;
  COOKIE_NAME: string = 'addToHomeScreenPrompt';
  displayPrompt: string = '';
  hasUserInstalled: boolean = false;
  deferredPrompt: any;
  isStandalone: boolean = false;

  constructor(
    public userAgentService: UserAgentService,
    private cookieService: CookieService,
    @Inject(DOMAIN_CONFIG_TOKEN)
    public config: AppConfig,) {
    this.trackStandalone();
  }

  ngOnInit(): void {
    this.checkForPwaInstall();
  }

  determinePrompt() {
    const addToHomeScreenPromptCookie = this.cookieService.get(this.COOKIE_NAME);
    if (addToHomeScreenPromptCookie !== 'dontShow' && !this.isStandalone && !this.hasUserInstalled) {
      // No support for PWA yet
      if (this.userAgentService?.isFirefox || this.userAgentService?.isOpera) {
        return;
      }

      // iOS Mobile Device
      if (this.userAgentService?.isSafari && this.userAgentService?.isIOS && this.userAgentService?.isMobile) {
        this.displayPrompt = this.deviceTypes.iOSMobileSafari;
      }

      // iOS Ipad Device
      if (this.userAgentService?.isSafari && this.userAgentService?.isIpad) {
        this.displayPrompt = this.deviceTypes.iosIpadSafari;
      }

      // Android mobile device
      if (this.userAgentService?.isAndroid && this.userAgentService?.isMobile) {
        this.displayPrompt = this.deviceTypes.AndroidMobile;
      }


      // Desktop device
      if (!this.userAgentService?.isMobile && !this.userAgentService.isIpad) {
        this.displayPrompt = this.deviceTypes.Desktop;
      }
    }
  }

  closePrompt() {
    this.displayPrompt = '';
  }

  doNotShowAgain() {
    const date = _moment();
    date.add(1, 'years');
    this.cookieService.set(this.COOKIE_NAME, 'dontShow', { expires: date.toDate() });
    this.closePrompt();
  }

  checkForPwaInstall() {
    console.log("CHECK FOR PWA INSTALL!!");
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log("Before install Prompt!");
      e.preventDefault();
      this.deferredPrompt = e;
      if (this.deferredPrompt) {
        this.determinePrompt();
      }
    });


    window.addEventListener('appinstalled', (evt) => {
      this.trackInstalled();
      this.clearInstallPrompt();
    });

    this.determinePrompt();
  }


  trackInstalled() {
    this.hasUserInstalled = true;
  }

  addToHomeScreen() {
    if (!this.deferredPrompt) {
      return;
    }

    // We show the prompt
    this.deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {

        if (choiceResult.outcome === 'accepted') {
          // no matter the outcome, the prompt cannot be reused ON MOBILE
          // for 3 months or until browser cache is cleared?
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User rejected the A2HS prompt');
        }

        this.clearInstallPrompt();
      });
  }

  clearInstallPrompt() {
    this.deferredPrompt = null;
  }


  trackStandalone() {
    const isStandalone = this.checkStandalone();
    if (isStandalone) {
      this.isStandalone = true;
    }
  }

  checkStandalone(): boolean {
    return (
      (window.matchMedia('(display-mode: standalone)').matches) || ("standalone" in (window as any).navigator &&
        (window as any).navigator.standalone)
    );
  }
}