
import { environment } from './../../environments/environment';

export const APP_CONFIG: any = {
  AUTH: {
    SESSION_TOKEN_NAME: 'X-Auth-Token',
    WWW_AUTHENTICATE_HEADER: 'www-authenticate',
    API: {
      CLIENT_COMMUNICATION: environment.CLIENT_COMMUNICATION_API_URL
    }
  },
};

export const DOMAIN_CONFIG_TOKEN = Symbol('APP_CONFIG');

export interface AppConfig {
  logoUrl: string;
  logoText?: string;
  defaultThemeName?: string;
  name?: string;
}

export interface DomainConfig {
  [key: string]: AppConfig
}

enum AppConfigType {
  default_partner = 'default_partner',
  medifin_partner = 'medifin_partner'
}

const baseConfigs: DomainConfig = {
  default_partner: {
    logoUrl: 'assets/images/itf.png',
    logoText: 'Partners',
    defaultThemeName: 'primary-theme.css',
    name: 'Partners',
  },
  medifin_partner: {
    logoUrl: 'assets/images/medifin/Medifin_Logo_color.png',
    logoText: '',
    defaultThemeName: 'medifin-theme.css',
    name: 'Medifin',
  },
};

const hostsConfigMap: { [host: string]: keyof typeof baseConfigs } = {
  'localhost:4201': AppConfigType.default_partner,
  'localhost:4202': AppConfigType.medifin_partner,
  'partners.production.aws.smilecredit.bg': AppConfigType.default_partner,
  'partners.staging.aws.smilecredit.bg': AppConfigType.default_partner,
  'partners.medifin.bg': AppConfigType.medifin_partner,
  'partners.staging.medifin.bg': AppConfigType.medifin_partner,
};


export function getDomainBasedAppConfig(host: string): AppConfig {
  console.log({host});
  const configKey = hostsConfigMap[host] || AppConfigType.default_partner;
  return baseConfigs[configKey];
}