<mat-card>
    <mat-card-header>
        <mat-card-title>
            <span [innerHTML]="title"></span>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <span [innerHTML]="message"></span>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <div  class="form-actions">
            <button mat-stroked-button type="button" (click)="close(false)">
                {{ 'global.close' | translate | uppercase }}
           </button>
            <button color="primary" mat-stroked-button type="button" (click)="close(true)">
                <span>{{ acceptButtonLabel | translate | uppercase }}</span>
            </button>
        </div>
    </mat-card-actions>
</mat-card>
