import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _loading: boolean = false;
  public loadingBus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.loading);

  set loading(loading: boolean) {
    this._loading = loading;
    this.loadingBus$.next(this.loading);
  }

  get loading() {
    return this._loading;
  }

  constructor(
  ) {
    this.loading = true;
  }
}
