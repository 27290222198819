import { NgModule } from "@angular/core";
import { MessageComponent } from "./message/message.component";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { } from "./error-dialog/error-dialog.component";
import { DynamicFormFieldComponent } from './dynamic-form/dynamic-form-field/dynamic-form-field.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ProductPlanSliderComponent } from "./product-plan-slider/product-plan-slider.component";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FilterAutocompleteOptionsPipe } from './dynamic-form/filter-autocomplete-options.pipe';
import { IdCardFileUploadComponent } from "./id-card-file-upload/id-card-file-upload.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonModule } from "@angular/material/button";
import { FormControlClearComponent } from "./form-control-clear/form-control-clear.component";
import { ThemePickerComponent } from './theme-picker/theme-picker.component';
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DarkModeToggleComponent } from "./dark-mode-toggle/dark-mode-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatListModule } from "@angular/material/list";
import { ApplicationStatusIconComponent } from "../application/application-status-icon/application-status-icon.component";
import { ParseUserAgentPipe } from "./pipes/parse-user-agent.pipe";
import { ItfgDateFormatPipe } from "./pipes/itfg-date-format.pipe";
import { DirectivesModule } from "src/app/directives/directives.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    MessageComponent,
    DynamicFormFieldComponent,
    ProductPlanSliderComponent,
    FilterAutocompleteOptionsPipe,
    IdCardFileUploadComponent,
    FileUploadComponent,
    FormControlClearComponent,
    ThemePickerComponent,
    DarkModeToggleComponent,
    ApplicationStatusIconComponent,
    ParseUserAgentPipe,
    ItfgDateFormatPipe,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    DirectivesModule,
    MatDialogModule,
  ],
  providers: [
  ],
  exports: [MessageComponent, DynamicFormFieldComponent, ProductPlanSliderComponent, IdCardFileUploadComponent,
    FileUploadComponent, FormControlClearComponent, ThemePickerComponent, DarkModeToggleComponent, ApplicationStatusIconComponent, ParseUserAgentPipe, ItfgDateFormatPipe],
})
export class SharedModule { }
