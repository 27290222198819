import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { TranslateModule } from '@ngx-translate/core';
import { authGuard } from './auth.guard';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MfaVerificationComponent } from '../mfa-verification/mfa-verification.component';

const routes: Routes = [
  {
    path: 'email-confirmation/:hashedEmail/:emailConfirmationToken/:passwordResetToken',
    component: EmailConfirmationComponent,
    data: {
      titleKey: 'profile.emailConfirm',
      breadcrumbKey: 'profile.emailConfirm'
    }
  },
  {
    path: 'email-confirmation/:hashedEmail/:emailConfirmationToken',
    component: EmailConfirmationComponent,
    data: {
      titleKey: 'profile.emailConfirm',
      breadcrumbKey: 'profile.emailConfirm'
    }
  },
  {
    path: 'password-recovery/:hashedEmail/:passwordResetToken',
    component: PasswordResetComponent,
    data: {
      titleKey: 'profile.passwordRecovery',
      breadcrumbKey: 'profile.passwordRecovery'
    }
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    data: {
      titleKey: 'profile.passwordRecovery',
      breadcrumbKey: 'profile.passwordRecovery'
    }
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { titleKey: 'breadcrumbs.login', breadcrumbKey: 'breadcrumbs.login' },
      },
      {
        path: '2fa',
        component: MfaVerificationComponent,
        data: {
          titleKey: 'twoFactorAuth.mfa',
          breadcrumbKey: 'twoFactorAuth.mfa'
        },
      },
      { path: '', pathMatch: 'full', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled" }), TranslateModule],
  declarations: [],
  exports: [RouterModule],
  //   providers: [AuthGuardService],
})
export class AuthRoutingModule {}
