import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'itfg-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent {

  get email(): string {
    return this.emailForm.get('email').value;
  }

  public emailForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private notifications: NotificationService,
  ) {
    this.emailForm = this.fb.group({email: ['', [Validators.required, Validators.email]]})
  }

  onSubmit() {
    if (this.emailForm.valid) {
      this.auth.requestTemporaryPassword$(this.email).subscribe({
        next: this.onSubmitSuccess.bind(this),
        error: this.notifications.handleHttpErrors.bind(this.notifications),
      })
    }
  }

  onSubmitSuccess(res: any) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Успешно изпратена заявка за временна парола!',
        message: 'Изпратихме инструкции на зададеният имейл.',
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['auth', 'login'],  { state: { email: this.email } });
    });
  }

}
