import { Injectable } from '@angular/core';

import { EMPTY, Observable, lastValueFrom, of, Subject } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { UserLoginDto, UserDetails } from '../types/user.type';
import { RequestService } from './request.service';
import { StorageService } from './storage.service';
import { APP_CONFIG } from '../config/app.config';
import { HttpResponse } from '@angular/common/http';
import { ClientCommunicationRequestService } from './client-communication.request.service';
import { LoginResponse } from '../types/login-response';

const AUTH_CONFIG = APP_CONFIG.AUTH;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string | null = null;
  public currentUser: UserDetails | null = null;
  public currentUserBus$: Subject<UserDetails> = new Subject();

  constructor(
    private request: RequestService,
    private clientCommunicationRequest: ClientCommunicationRequestService,
    private storage: StorageService
  ) {}

  login$(dto: UserLoginDto): Observable<LoginResponse> {
    return this.request
      .post(['partner', 'auth', 'login'], {
        withCredentials: true,
        body: dto,
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        switchMap((response: HttpResponse<{}>) => {
          const token: string | null = response.headers.get(AUTH_CONFIG.SESSION_TOKEN_NAME);
          const authenticateHeader = response.headers.get(AUTH_CONFIG.WWW_AUTHENTICATE_HEADER);
          const res: LoginResponse = new LoginResponse({
            isSuccessAuthentication: true,
            requiresTwoFactorAuth: authenticateHeader === "OTP",
            body: response.body,
          })

          if (token) {
            this.storage.setItem(AUTH_CONFIG.SESSION_TOKEN_NAME, token);
          }
          
          return of(res);
        }),
        tap((loggedIn: any) => {
          if (loggedIn) {
            this.loadCurrentUser();
          }
        })
      );
  }

  logout$() {
    return this.request
      .post(['partner', 'auth', 'logout'])
      .pipe(tap(() => {
        this.storage.removeItem(AUTH_CONFIG.SESSION_TOKEN_NAME);
      }));
  }

  emailConfirmation$(emailConfirmToken: string) {
    return this.request.post(['partner', 'profile', 'email', 'confirm'], {
      body: emailConfirmToken
    });
  }

  setPassword$(token: string, password: string) {
    return this.request.put(['partner', 'profile', 'password'], {
      body: {
        token,
        password,
      }
    })
  }

  // TODO: Delete after after setPassword$ PUT method is fixed
  setPasswordTmp$(token: string, password: string) {
    // return of({});
    return this.request.post(['partner', 'profile', 'password-tmp'], {
      body: {
        token,
        password,
      }
    });
  }

  requestTemporaryPassword$(email: string) {
    return this.clientCommunicationRequest.post(['partners','email','temporary-password'], {
      body: {
        email
      }
    })
  }

  revokeTrustedDevices() {
    return this.request.post(['partner', 'auth', '2fa', 'devices', 'revoke']);
  }

  revokeTrustedDeviceById(id: number) {
    return this.request.post(['partner', 'auth', '2fa', 'devices', id, 'revoke']);
  }

  loadCurrentUser(): Promise<UserDetails> {
    return lastValueFrom(this.getCurrentUser$())
    .then((user => {
      this.currentUserBus$.next(user);
      return this.currentUser = user;
    }));
  }

  getCurrentUser$() {
    return this.request.get(['partner', 'profile']).pipe(map(u => new UserDetails(u)))
  }

  isAuthenticated(): boolean {
    const authToken = this.getAuthToken();
    return !!authToken;
  }

  getAuthToken(): string | null {
    return this.storage.getItem(AUTH_CONFIG.SESSION_TOKEN_NAME);
  }
}
