<div [formGroup]="form">
    <div [ngSwitch]="formField.controlType">

        <ng-container *ngSwitchCase="'product-plan-slider'">
            <itfg-product-plan-slider
                [viewConfig]="{ showSummaryFooter: true, showPeriodSlider: true }"
                (targetPlanChanged)="onProductPlanChanged($event)">
            </itfg-product-plan-slider>
        </ng-container>

        <ng-container *ngSwitchCase="'product-plan-slider-minimal'">
            <itfg-product-plan-slider
                [viewConfig]="{ showSummaryFooter: false, showPeriodSlider: false }"
                (targetPlanChanged)="onProductPlanChanged($event)">
            </itfg-product-plan-slider>
        </ng-container>

        <mat-form-field *ngSwitchCase="'inputbox'" [floatLabel]="formField.floatLabel">
            <mat-label>{{formField.label}}</mat-label>
            <input itfgTrimOnBlur [type]="formField.type" [formControlName]="formField.key" matInput
                [placeholder]="formField.placeholder" [readonly]="formField.readonly"/>
            <itfg-form-control-clear *ngIf="formField.showValueClearButton" matSuffix [control]="form.get(formField.key)"></itfg-form-control-clear>

            <mat-icon *ngIf="formField.preffixIcon" matPrefix>
                {{ formField.preffixIcon }}
            </mat-icon>
            <span *ngIf="formField.prefixText" class="mat-input-text-prefix" matPrefix>
                {{ formField.prefixText }}
            </span>
            <mat-error>Моля, въведете валидна стойност за {{ formField.label }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'dropdown'">
            <mat-label>{{formField.label}}</mat-label>
            <mat-icon *ngIf="formField.preffixIcon" matPrefix>
                {{ formField.preffixIcon }}
            </mat-icon>
            <mat-select [formControlName]="formField.key" [placeholder]="formField.placeholder">
                <mat-option *ngFor="let opt of formField.optionsBus$ | async" [value]="opt.id">
                    {{opt.type || opt.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'autocomplete'">
            <mat-label>{{formField.label}}</mat-label>
            <input type="text" [placeholder]="formField.placeholder" aria-label="Country" matInput
                [formControlName]="formField.key" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnAutocomplete">
                <mat-option *ngFor="let opt of (formField.optionsBus$ | async | filterAutocompleteOptions: form.get(formField.key) | async)" [value]="opt">
                    {{opt.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'image_upload'" [floatLabel]="formField.floatLabel">
            <mat-label>{{formField.label}}</mat-label>
            <input (click)="idFrontUpload.triggerUploadButton()"
                   [value]="form.controls[formField.key].value?.name"
                   [placeholder]="formField.placeholder"
                   readonly
                   [formControlName]="formField.key" matInput />
            <mat-icon *ngIf="formField.preffixIcon" matPrefix>
                {{ formField.preffixIcon }}
            </mat-icon>
            <itfg-form-control-clear *ngIf="formField.showValueClearButton" matSuffix [control]="form.get(formField.key)"></itfg-form-control-clear>
            <itfg-file-upload #idFrontUpload matSuffix
                [captureMode]="formField.captureMode"
                (fileSelected)="onFileSelected($event, formField.key)"></itfg-file-upload>
            <mat-error>Моля, качете всички документи</mat-error>
        </mat-form-field>

        <ng-container *ngSwitchCase="'payment_type_dental'" >
            bruh
            <!-- <mat-form-field>
                <mat-label>Метод на плащане</mat-label>
                <mat-select [formControlName]="formField.key" [placeholder]="formField.placeholder">
                    <mat-option *ngFor="let opt of formField.optionsBus$ | async" [value]="opt.id">
                        {{opt.type}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <!-- <div class="payment-method-options">
                <mat-selection-list [multiple]="false">
                    <mat-list-option [selected]="true" [value]="1">
                        Сметка на партньора
                    </mat-list-option>
                    <mat-list-option [value]="2">Сметка на клиента</mat-list-option>
                </mat-selection-list>
            </div> -->
        </ng-container>
          
    </div>

</div>