import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if (!authService.currentUser && authService.isAuthenticated()) {
    await authService.loadCurrentUser();
  }

  console.log(authService.currentUser)

  const isAuthenticated = authService.isAuthenticated();
  const roles = authService.currentUser?.roles || [];
  const preauthorizationStep = roles.length > 0 && roles.every((role: string) => role === 'PRE_AUTH_PARTNER');
  const tryingToAccessPreAuth = /auth\/2fa/.test(state.url);

  if (tryingToAccessPreAuth) {
    if (preauthorizationStep) {
      return true;
    } else {
      return router.navigate(['/auth/login']);
    }
  }

  if (preauthorizationStep) {
    return router.navigate(['/auth/2fa'])
  }

  if (!isAuthenticated) {
    return true;
  } else {
    router.navigate(['/home']);
    return false;
  }

};
