import { Component, Inject, ViewChild } from '@angular/core';
import { StyleManager } from '../style-manager';
import { StorageService } from 'src/app/services/storage.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AppTheme } from 'src/app/types/app-theme.type';
import { AppConfig, DOMAIN_CONFIG_TOKEN } from 'src/app/config/app.config';

@Component({
  selector: 'itfg-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent {
  currentTheme: AppTheme;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;

  themes: AppTheme[] = [
    {
      displayName: 'Кралско синьо',
      name: 'medifin-theme',
      isDark: true,
      isDefault: false,
    },
    {
      displayName: 'Райска ябълка',
      name: 'primary-theme',
      isDark: false,
      isDefault: false,
    },
    {
      displayName: 'Маслено синьо',
      name: 'dental-theme',
      isDark: false,
    },
    {
      displayName: 'Среднощно синьо',
      name: 'pink-blue-grey',
      isDark: false,
    },
  ];

  constructor(public styleManager: StyleManager,
              private storage: StorageService,
              @Inject(DOMAIN_CONFIG_TOKEN)
              private config: AppConfig,
    ) {
    const themeName = this.storage.getItem('theme-storage-current-name')
    const theme = this.themes.find(currentTheme => currentTheme.name === themeName);
    const defaultTheme = this.themes.find(t => t.name === this.config.defaultThemeName.replace('.css', ''));
    if (defaultTheme) {
      defaultTheme.isDefault = true;
      defaultTheme.displayName = 'Основна тема'
      this.themes.sort((a, b) => Number(b.isDefault)-Number(a.isDefault))
    }
    this.currentTheme = theme || this.themes.find(theme => theme.isDefault);
  }

  selectTheme(themeName: string) {
    const theme = this.themes.find(currentTheme => currentTheme.name === themeName);

    if (!theme) {
      return;
    }

    this.currentTheme = theme;

    this.styleManager.setStyle('theme', `${theme.name}.css`);

    if (this.currentTheme) {
      this.storage.setItem('theme-storage-current-name', this.currentTheme.name);
    }
  }
}
