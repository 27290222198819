<div class="slider-container">
  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <mat-label>{{ "global.product" | translate }}</mat-label>
        <mat-select [(value)]="product" placeholder="{{ 'global.product' | translate }}" required>
          <mat-option *ngFor="let product of productList" [value]="product">{{
            product.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="calculator-content">
        <div class="slider-label-container">
          <label class="slider-text-label"></label>
          <label class="slider-value-label principal">{{ allowedPrincipalList[selectedPrincipalIndex] | currency }}</label>
        </div>
        <div class="flex-layout-row">
          <button mat-icon-button type="button" (click)="principalStepDown()" [disabled]="selectedPrincipalIndex <= 0">
            <mat-icon>remove</mat-icon>
          </button>
          <mat-slider [min]="0" [max]="allowedPrincipalList.length - 1" [step]="1" (input)="onPrincipalInputChange()">
            <input (dragEnd)="setTargetPlan()" matSliderThumb [(ngModel)]="selectedPrincipalIndex" #principalSlider />
          </mat-slider>
          <button mat-icon-button type="button" (click)="principalStepUp()" [disabled]="selectedPrincipalIndex >= (allowedPrincipalList.length-1)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <ng-container *ngIf="viewSettings.showPeriodSlider">
          <div class="slider-label-container">
            <label class="slider-txt-label"></label>
            <label class="slider-value-label period">{{ periodsList[selectedPeriodIndex] }}&nbsp;{{
              product.periodUnit === "MONTH" ? "месеца" : "дни"
              }}</label>
          </div>
          <div class="flex-layout-row">
            <button mat-icon-button type="button" (click)="periodStepDown()" [disabled]="selectedPeriodIndex <= 0">
              <mat-icon>remove</mat-icon>
            </button>
            <mat-slider [min]="0" [max]="periodsList.length - 1" [step]="1">
              <input (valueChange)="onPeriodInputChange()" matSliderThumb [(ngModel)]="selectedPeriodIndex" #periodSlider />
            </mat-slider>
            <button mat-icon-button type="button" (click)="periodStepUp()" [disabled]="selectedPeriodIndex >= (periodsList.length-1)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-footer *ngIf="viewSettings.showSummaryFooter">
      <mat-card-actions>
        <div class="calculator-plan-values-container">
          <ng-container *ngTemplateOutlet="calculatorPlanValue; context: { title: 'Вноска', value: productPlanSummary.installmentAmount }"></ng-container>
          <ng-container *ngTemplateOutlet="calculatorPlanValue; context: { title: 'Общо оскъпяване', value: productPlanSummary.totalCostIncrease }"></ng-container>
          <ng-container *ngTemplateOutlet="calculatorPlanValue; context: { title: 'Сума за връщане', value: productPlanSummary.totalReturnAmount }"></ng-container>
        </div>
      </mat-card-actions>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #calculatorPlanValue let-title="title" let-value="value">
  <div class="calculator-plan-values-box">
    <label class="installment-amount-label">{{title}}&nbsp;</label>
    <span class="installment-amount-label-value">{{ value | currency }}</span>
  </div>
</ng-template>