import { Injectable } from "@angular/core";
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar, MatSnackBarRef, SimpleSnackBar, TextOnlySnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { EmbeddedNotificationComponent } from "../components/embedded-notification/embedded-notification.component";
import { HttpErrorResponse } from "@angular/common/http";
import { UtilsService } from "./utils.service";

const showBaseSnackbarNotificationDefaults = {
  duration: 2000,
};

const showSnackbarNotificationDefaults = {
  ...showBaseSnackbarNotificationDefaults,
  dismissText: 'Dismiss',
};

const showLocalizedSnackbarNotificationDefaults = {
  ...showBaseSnackbarNotificationDefaults,
  dismissText: 'global.close',
  duration: 3000
};

export interface SnackbarNotificationOptions {
  notificationText?: string;
  dismissText?: string;
  duration?: number;
  action?: 'info' | 'success' | 'error';
  verticalPosition?: MatSnackBarVerticalPosition;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  panelClass?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private utils: UtilsService,
  ) { }

  public showSnackbarNotification({
    notificationText,
    dismissText = showSnackbarNotificationDefaults.dismissText,
    duration = showSnackbarNotificationDefaults.duration,
    action = 'info',
    verticalPosition = 'bottom',
    horizontalPosition = 'end',
  }: SnackbarNotificationOptions = showSnackbarNotificationDefaults): MatSnackBarRef<any> {
    // return this.snackBar.openFromComponent(EmbeddedNotificationComponent, {
    //   data: '',
    //   duration: duration,
    //   verticalPosition,
    //   horizontalPosition,
    //   panelClass: action,
    // });
    return this.snackBar.open(notificationText as string, dismissText, {
      duration: duration,
      verticalPosition,
      horizontalPosition,
      panelClass: action,
    });
  }

  public showLocalizedSnackbarNotification({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
    action = 'info',
    verticalPosition = "bottom",
    horizontalPosition = 'center',
  }: SnackbarNotificationOptions = showLocalizedSnackbarNotificationDefaults): MatSnackBarRef<any> {
    const message: any = notificationText;
    const translations = this.translate.instant([message, dismissText]);
    return this.snackBar.openFromComponent(EmbeddedNotificationComponent,
      {
        data: {
          message: translations[message],
          dismissText: translations[dismissText],
          action,
        },
        duration: duration,
        verticalPosition,
        horizontalPosition,
        panelClass: action,
      });
    // return this.snackBar.open(
    //   translations[message],
    //   translations[action],
    //   {
    //     duration: duration,
    //     verticalPosition: 'bottom',
    //     horizontalPosition: 'right',
    //     panelClass: action,
    //   }
    // );
  }

  public showLocalizedSuccessMessage({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
  }: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.showLocalizedSnackbarNotification({
      notificationText,
      dismissText,
      duration,
      action: 'success',
    });
  }

  handleHttpErrors(res: HttpErrorResponse) {
    const parsedMessage = this.utils.parseErrorMessage(res);
    const translatedMessage = this.translate.instant(parsedMessage);
    this.showLocalizedErrorMessage({ notificationText: translatedMessage.replace('errors.', '') });
  }

  public showLocalizedErrorMessage({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
  }: any): MatSnackBarRef<TextOnlySnackBar> {
    return this.showLocalizedSnackbarNotification({
      notificationText,
      dismissText,
      duration,
      action: 'error',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'snackbar-error',
    });
  }

}