<mat-card [class]="data.action">
    <mat-card-content>
        <div>
            <span>
                <mat-icon *ngIf="data.action === 'success'">verified</mat-icon>
                <mat-icon *ngIf="data.action === 'error'">error</mat-icon>
                <span class="notification-text">{{ data.message }}</span>
            </span>
            <span class="spacer"></span>
            <mat-icon class="close" (click)="dismiss()">close</mat-icon>
        </div>
    </mat-card-content>
</mat-card>