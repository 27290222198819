import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import _moment from 'moment';
import 'moment/locale/bg';
import { DateFormat } from 'src/app/config/date-format';

@Pipe({
  name: 'itfgDateFormat',
})
export class ItfgDateFormatPipe extends DatePipe implements PipeTransform {
  override transform(
    value: any,
    format: string = DateFormat.DATE_TIME,
    timezone?: string,
    locale?: string
  ): any {
    if (format === DateFormat.DATE_TIME) {
      const date = super.transform(value, DateFormat.DATE, timezone, locale);
      const time = super.transform(value, DateFormat.TIME, timezone, locale);
      return [date, time].filter(e => e !== '' && e !== null).join(', ');
    }

    if (format === DateFormat.FROM_NOW) {
      return _moment(value).fromNow();
    }

    return super.transform(value, DateFormat.DATE, timezone, locale);
  }
}
