import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'parseUserAgent' })
export class ParseUserAgentPipe implements PipeTransform {
  transform(userAgent: string, format: 'full' | 'icon' | 'label' = 'label'): any {
    let browserName = "Unknown";
    let fullVersion = "Unknown";

    const browserRegex = [
        { name: 'Edge', regex: /Edg\/([0-9._]+)/ },
        { name: 'Opera', regex: /Opera\/([0-9._]+)|OPR\/([0-9._]+)/ },
        { name: 'Chrome', regex: /Chrome\/([0-9._]+)/ },
        { name: 'Safari', regex: /Version\/([0-9._]+).*Safari/ },
        { name: 'Firefox', regex: /Firefox\/([0-9._]+)/ },
        { name: 'Internet Explorer', regex: /MSIE\s([0-9._]+);|Trident.*rv:([0-9._]+)/ }
    ];

    for (let i = 0; i < browserRegex.length; i++) {
        const match = userAgent.match(browserRegex[i].regex);
        if (match) {
            browserName = browserRegex[i].name;
            fullVersion = match[1] || match[2];
            break;
        }
    }

    const majorVersion = fullVersion.split('.')[0];

    switch (format) {
      case 'full':
        return `${browserName} ${majorVersion}`;
      case 'icon':
        return browserName.toLowerCase()
      default:
        return `${browserName}`;
    }

  }
}
