<div *ngIf="data.title"
     class="modal-dialog-title">
  <p [ngClass]="{'itfg-warn': data.titleWarningStyle}">{{ data.title }}</p>
</div>

<div class="mat-dialog-content">
  <p class="dialog-message">
    <span [innerHtml]="data.message"></span>
  </p>
</div>

<div class="mat-dialog-actions">
  <button mat-button
          [mat-dialog-close]="false"
          cdkFocusInitial>{{ data.cancelButton || ('global.cancel'|translate) }}</button>
  <button mat-button
          color="accent"
          [mat-dialog-close]="true">{{ data.acceptButton || ('global.confirm'|translate) }}</button>
</div>
