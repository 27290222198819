import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { SpinnerOverlayService } from './services/spinner-overlay.service';
import { LoaderService } from './services/loader.service';
import { environment } from 'src/environments/environment';
import { StyleManager } from './components/shared/style-manager';
import { StorageService } from './services/storage.service';
import { AppConfig, DOMAIN_CONFIG_TOKEN } from './config/app.config';

@Component({
  selector: 'itfg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'partners';
  enviromentIsProduction = environment.production;

  constructor(
    private translateService: TranslateService,
    public auth: AuthService,
    private loader: LoaderService,
    private spinnerOverlay: SpinnerOverlayService,
    public styleManager: StyleManager,
    public storage: StorageService,
    @Inject(DOMAIN_CONFIG_TOKEN)
    public config: AppConfig,
  ) {
    this.loader.loadingBus$.subscribe((isLoading: boolean) => {
      if (isLoading) {
        this.spinnerOverlay.show();
      } else {
        this.spinnerOverlay.hide();
      }
    })
    this.translateService.setDefaultLang('bg');
    this.translateService.use('bg');
  }

}
