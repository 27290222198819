import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { CreditsService } from 'src/app/services/credits.service';
import { UserDocumentType } from 'src/app/types/user.type';

@Component({
  selector: 'itfg-id-card-file-upload',
  templateUrl: './id-card-file-upload.component.html',
  styleUrls: ['./id-card-file-upload.component.scss']
})
export class IdCardFileUploadComponent {

  creditId: number;
  userDocumentType = UserDocumentType;
  controlNames = [
    UserDocumentType.ID_BACK,
    UserDocumentType.ID_FRONT,
    UserDocumentType.SELFIE,
  ]

  isUploadingFile = {
    [UserDocumentType.ID_BACK]: false,
    [UserDocumentType.ID_FRONT]: false,
    [UserDocumentType.SELFIE]: false,
  }

  uploadedSuccessfully = {
    [UserDocumentType.ID_BACK]: false,
    [UserDocumentType.ID_FRONT]: false,
    [UserDocumentType.SELFIE]: false,
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { creditId: number },
    private creditsService: CreditsService,
    private dialogRef: MatDialogRef<IdCardFileUploadComponent>
  ) {
    this.creditId = data.creditId;
    this.dialogRef.disableClose = false;
  }

  idCardFileForm = new FormGroup({
    [UserDocumentType.ID_FRONT]: new FormControl(null, [Validators.required]),
    [UserDocumentType.ID_BACK]: new FormControl(null, [Validators.required]),
    [UserDocumentType.SELFIE]: new FormControl(null, [Validators.required]),
  });

  onFileSelected(file: File, type: UserDocumentType) {
    this.isUploadingFile[type] = true;
    const formData: FormData[] = [];
    formData.push(new FormData());
    formData[0].append('document', file);
    this.creditsService
      .uploadCreditDocumentByType$(this.creditId, type, formData[0])
      .pipe(
        finalize(() => {
          this.isUploadingFile[type] = false;
        })
      )
      .subscribe((res) => {
        this.uploadedSuccessfully[type] = true;
        this.dialogRef.disableClose = true;
      });
    this.idCardFileForm.get(type).patchValue(file);
  }

  onSubmit() {
    this.dialogRef.close(true);
  }

}
