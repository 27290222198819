import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[itfgTrimOnBlur]'
})
export class TrimOnBlurDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur')
  onBlur() {
    const currentValue = this.el.nativeElement.value;
    const trimmedValue = currentValue.trim();
    this.control.control?.setValue(trimmedValue);
  }

}
