import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { StyleManager } from '../components/shared/style-manager';
import { StorageService } from './storage.service';
import { DOCUMENT } from '@angular/common';
import { AppConfig, DOMAIN_CONFIG_TOKEN } from '../config/app.config';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private renderer: Renderer2;

    constructor(
        private styleManager: StyleManager,
        private storage: StorageService,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT)
        private document: Document,
        @Inject(DOMAIN_CONFIG_TOKEN)
        public config: AppConfig,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    loadTheme(): Promise<any> {
        const themeName = this.storage.getItem('theme-storage-current-name');
        const prefDarkTheme: boolean = !!this.storage.getItem('prefers-color-scheme');
        if (themeName) {
            this.styleManager.setStyle('theme', `${themeName}.css`);
        } else {
            const themeToSet = this.config.defaultThemeName || `primary-theme.css`;
            this.styleManager.setStyle('theme', themeToSet);
        }
        if (prefDarkTheme) {
            this.renderer.addClass(this.document.body, 'dark-theme');
        } else {
            this.renderer.removeClass(this.document.body, 'dark-theme');
        }
        return Promise.resolve();
    }
}
