import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import {HttpRequest, HttpHandler} from '@angular/common/http';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogComponent} from '../components/shared/error-dialog/error-dialog.component';
import { ExceptionType } from '../types/exceptions.type';

const ERROR_MESSAGE_TYPES: any = {
  'com.itfgroup.client.profile.error.InvalidTokenException': 'com.itfgroup.client.profile.error.InvalidTokenException',
  [ExceptionType.BAD_CREDENTIALS]: ExceptionType.BAD_CREDENTIALS,
  [ExceptionType.INVALID_TOTP_CODE]: ExceptionType.INVALID_TOTP_CODE,
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  router: Router;
  dialog: MatDialog;

  constructor(
    router: Router,
    dialog: MatDialog,
  ) {
    this.router = router;
    this.dialog = dialog;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const nextRequest = request.clone();

    return next.handle(nextRequest).pipe(
      tap({
        error: (httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.status === 500) {
            let isExpectedError = false;
            if (httpErrorResponse.error && httpErrorResponse.error.exception) {
              isExpectedError =
                httpErrorResponse.error.exception.indexOf(
                  'DataIntegrityViolationException'
                ) !== -1
                  ? true
                  : false;
            }

            let typeOfError = 'default';

            if (isExpectedError) {
              if (nextRequest.method.toLowerCase() === 'delete') {
                typeOfError = 'delete';
              } else if (nextRequest.method.toLowerCase() === 'post') {
                typeOfError = 'post';
              }
            }

            this.openDialog(typeOfError, httpErrorResponse);
          } else if (
            httpErrorResponse.status === 400 &&
            !ERROR_MESSAGE_TYPES[httpErrorResponse.error.exception] &&
            !(
              httpErrorResponse.error &&
              httpErrorResponse.error.length > 0 &&
              httpErrorResponse.error[0].message
            ) && !(httpErrorResponse.error instanceof Blob && httpErrorResponse.error.type === 'application/json')
          ) {
            const typeOfError = 'default';
            this.openDialog(typeOfError, httpErrorResponse);
          } else if (httpErrorResponse.status === 0) {
            const typeOfError = 'default';
            this.openDialog(typeOfError, httpErrorResponse);
          }
        },
      })
    );
  }

  openDialog(type: string, errorMessage: HttpErrorResponse) {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        type: type,
        errorMessage: errorMessage,
      },
    });
  }
}
