import { Injectable } from "@angular/core";
import { CustomFormFieldBase } from "./dynamic-form-control-types/custom-form-field.type";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class FormFieldControlService {
    toFormGroup(formFields: CustomFormFieldBase<string>[]) {
        const group: any = {};

        formFields.forEach(formField => {
            if (formField.key === 'product_plan') {
                group['product'] = new FormControl(formField.value, formField.validators);
                // group['product_id'] = new FormControl(formField.value, formField.validators);
                group['principal'] = new FormControl(formField.value, formField.validators);
                group['period'] = new FormControl(formField.value, formField.validators);
                group['product_id'] = new FormControl(formField.value, formField.validators)
            } else {
                group[formField.key] = new FormControl(formField.value, formField.validators);
            }
        });

        const formGroup = new FormGroup(group);

        // Set event listeners
        formFields.forEach(formField => {
            if (formField.hasOwnProperty('trackChangesCallback') && formField.trackChangesCallback) {
                const control: FormControl = group[formField.key];
                control.valueChanges.subscribe((value) => formField.trackChangesCallback(formGroup, value))
            }
        });

        return formGroup;
    }
}