import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'itfg-info-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  title: string;
  message: string;
  acceptButtonLabel: string = 'global.confirm';
  closeButtonLabel: string = 'global.close';
  panelClass: string;
  requireAgreementCheckbox: boolean = false;
  agreementBoxMessage: string = null;
  acceptedAgreement: boolean = false;
  
  constructor(
    private dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: any,
  ) { 
    this.title = data.title;
    this.message = data.message;
    this.acceptButtonLabel = data.acceptButtonLabel || this.acceptButtonLabel;
    this.closeButtonLabel = data.closeButtonLabel;
    this.panelClass = data.panelClass;
    this.requireAgreementCheckbox =  data.requireAgreementCheckbox;
    this.agreementBoxMessage =  data.agreementBoxMessage;
  }

  close(accepted: boolean) {
    this.dialogRef.close(accepted);
  }
}
