import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MfaInstructionsDialog } from '../auth/mfa-instructions-dialog/mfa-instructions-dialog.component';
import { CreditsService } from 'src/app/services/credits.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'itfg-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrl: './mfa-verification.component.scss'
})
export class MfaVerificationComponent {
  data = '';
  errored: boolean = false;
  mfaSecretShown: boolean = false;
  qrCode: string = '';
  secret: string = '';
  // qrCode = "iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAABdElEQVR4Xu2WUYrDMAxEDb6WQVcX+FqC7DylLW63+7XWn0VLa7+Ao9FISbv+iva58YpDDiEO+Qfx1nr+jNHssaggUx+30Pacuagi3mf0y+3yYSwKiZOjMu3FJFobPdqXO9hInrA7mn5qvY1givmMX97ZR4gYgtHsua4grhR1rI+R1RuJK4hqFdrt3sOuWDXYSiIFZUemx5NFZHKu2oomJtkqoqB1uUCKPqpXQCTksEDRRuGWztpNsmiyenCNZK0hM+1xaZtpMdIgFYSpp/T8LlvmWULQU8OINIdpuTh+L2ldDpENGRZviu4lTnaSlKaa+QysIcFzwhAW7mRbQjj5VlWGxylVhGEkh5j+MpqsiujrGP1+SfHlDvYSBRBFs42LSFI3hgUNhl9KCMfiEKOr5uqQvWRSPJmxT8bRnXYJcc7VfucF5U3r7USKMl/TJOxVERVOSuqHmVRFgHkwJhmZaAXJN8icRHh/vYO95GsccghxyG7yAyp9cDYKs8URAAAAAElFTkSuQmCC";
  // secret = "BLRWUIGQSIWVBDUB";

  rememberMe: boolean = false;

  constructor(
    // private session: SessionService,
    private router: Router,
    private dialogService: MatDialog,
    private creditService: CreditsService,
    private authService: AuthService,
  ) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    const hasQrCode: boolean = state?.hasOwnProperty('qrCode');
    if (hasQrCode) {
      const {secret,qrCode} = this.router.getCurrentNavigation().extras.state;
      this.qrCode = qrCode;
      this.secret = secret;
    }
  }

  async submitTotpCode() {
    this.errored = true
    if (this.data.length !== 6) {
      return;
    }
    this.errored = false;
    this.creditService.verifyTotpToken(this.data, this.rememberMe).subscribe({
      next: async () => {
        await this.authService.loadCurrentUser();
        this.router.navigate(['/home']);
      },
      error: (err) => {
        this.errored = true;
      }
    })
  }

  openTwoFactorSetupInstructions() {
    this.dialogService.open(MfaInstructionsDialog, {
      maxWidth: '700px'
    });
  }

  cancelTwoFactorVerification() {
    // this.session.logout();
  }
}
