<div class="container">
    <h1 class="title">ДЕКЛАРАЦИЯ по чл. 42, ал. 2, т. 2 от ЗМИП</h1>
    <p class="text-justify my-4">Лице, видна политическа личност в Република България, в друга държава членка, или в
        трета държава означава физическо лице, което изпълнява или на което са били поверени следните важни обществени
        функции:</p>
    <p class="text-justify mb-4">Държавни глави, ръководители на правителства, министри и заместник-министри или
        помощник-министри; членове на Парламенти или на други законодателни органи; членове на Конституционни
        съдилища, на Върховни съдилища или на други висши органи на съдебната власт, чиито решения не подлежат на
        последващо обжалване освен при изключителни обстоятелства; членове на Сметна палата; членове на управителни
        органи на Централни банки; посланици и управляващи дипломатически мисии; висши офицери от въоръжените сили;
        членове на административни, управителни или надзорни органи на държавни предприятия и търговски дружества с
        едноличен собственик – държавата; кметове и заместник-кметове на общини, кметове и заместник-кметове на райони
        и председатели на общински съвети; членове на управителните органи на политически партии; ръководители и
        заместник-ръководители на международни организации, членове на управителни или надзорни органи в международни
        организации или лица, изпълняващи еквивалентна функция в такива организации.</p>
    <p class="text-justify mb-4">Свързани с видна политическа личност са следните категории лица: съпрузите или лицата,
        които живеят във фактическо съжителство на съпружески начала; низходящите от първа степен и техните съпрузи
        или лицата, с които низходящите от първа степен живеят във фактическо съжителство на съпружески начала;
        възходящите от първа степен и техните съпрузи или лицата, с които възходящите от първа степен живеят във
        фактическо съжителство на съпружески начала; роднините по съребрена линия от втора степен и техните съпрузи
        или лицата, с които роднините по съребрена линия от втора степен живеят във фактическо съжителство на
        съпружески начала; всяко физическо лице, за което се знае, че е действителен собственик съвместно с видна
        политическа личност на юридическо лице или друго правно образувание или се намира в други близки търговски,
        професионални или други делови взаимоотношения с видна политическа личност; всяко физическо лице, което е
        едноличен собственик или действителен собственик на юридическо лице или друго правно образувание, за което се
        знае, че е било създадено в полза на видна политическа личност.</p>
    <p class="text-justify mb-4">Известна ми е наказателната отговорност по чл. 313 от Наказателния кодекс за
        деклариране
        на неверни обстоятелства.</p>
    <p class="text-justify mb-4">Представянето на горепосоченото изявление е задължение, произтичащо от чл. 42, ал. 2,
        т.
        2 вр. с чл. 36 от Закона за мерките срещу изпирането на пари.</p>
</div>