import {Injectable} from '@angular/core';
import { BrandCodes } from 'src/app/types/application.type';

/**
 * Class for managing stylesheets. Stylesheets are loaded into named slots so that they can be
 * removed or changed later.
 */
@Injectable({providedIn: 'root'})
export class StyleManager {
  /**
   * Set the stylesheet with the specified key.
   */
  setStyle(key: string, href: string) {
    getLinkElementForKey(key).setAttribute('href', href);
  }

  async setStyleAsync(key: string, href: string): Promise<any> {
    return Promise.resolve().then(function () {
      return getLinkElementForKey(key).setAttribute('href', href);
    });
  }

  setThemeByBrand(brandCode: BrandCodes) {
    switch (brandCode) {
      case BrandCodes.CD:
        this.setStyle('theme', `dental-theme.css`);
        break;
      case BrandCodes.SC:
        this.setStyle('theme', `primary-theme.css`);
        break;
      default:
        this.removeStyle('theme');
        break;
      }
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  removeStyle(key: string) {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }
  
}

function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}

function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string) {
  return `style-manager-${key}`;
}
