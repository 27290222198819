import { NgModule } from '@angular/core';
import { TogglePasswordVisibilityDirective } from './toggle-password-visibility.directive';
import { TrimOnBlurDirective } from './trim-on-blur.directive';


@NgModule({
  imports: [
  ],
  declarations: [
    TogglePasswordVisibilityDirective,
    TrimOnBlurDirective
  ],
  exports: [TogglePasswordVisibilityDirective, TrimOnBlurDirective],
})
export class DirectivesModule {}
