import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PasswordValidator } from 'src/app/validation/itfg-validation';
import { mergeMap } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserLoginDto } from 'src/app/types/user.type';
import { LoginResponse } from 'src/app/types/login-response';

@Component({
  selector: 'itfg-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  public confirmedSuccessfully: boolean = true;
  public paswordResetForm: FormGroup = new FormGroup({});
  public passwordResetToken: string = '';
  public hasError: boolean = false;
  public errorMessage: string = '';
  public email: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private notifications: NotificationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.paswordResetForm = this.createpaswordResetForm();
    const { passwordResetToken, hashedEmail } = this.route.snapshot.params;
    this.passwordResetToken = passwordResetToken;
    this.email = atob(hashedEmail);
  }

  createpaswordResetForm() {
    return this.fb.group({
      password: ['', { validators: [Validators.required, Validators.minLength(8)] }],
      confirmPassword: ['', { validators: [Validators.required] }],
    },
      { validators: [PasswordValidator.confirmed('password', 'confirmPassword')] }
    );
  }

  onSubmit() {
    if (this.paswordResetForm.valid) {
      this.hasError = false;
      const newPassword: string = this.paswordResetForm.get('password').value;
      const loginDto = new UserLoginDto({
        identity: this.email,
        password: newPassword,  
      });
      this.auth.setPassword$(this.passwordResetToken, newPassword)
        .pipe(
          mergeMap(() => this.auth.login$(loginDto))
        )
        .subscribe({
          next: (response: LoginResponse) => {
            this.notifications.showLocalizedSuccessMessage({ notificationText: 'profile.successfullySetPassword' });
            // TODO: CHECK THIS
            if (response.isSuccessAuthentication) {
              this.router.navigate(['/home']);
            }
          },
          error: this.handleHttpError.bind(this),
        })
    }
  }

  handleHttpError(res: HttpErrorResponse) {
    const message: string = this.utils.parseErrorMessage(res);
    this.hasError = true;
    this.errorMessage = message;
  }

}
