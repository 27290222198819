<span mat-dialog-title>Верификация на самоличност</span>
<span mat-dialog-subtitle>Моля, качете нужните файлове</span>
<div mat-dialog-content>
    <form [formGroup]="idCardFileForm" (ngSubmit)="onSubmit()" novalidate>
        <mat-card-content>
            <div class="form-fields-layout">
                <ng-container *ngFor="let controlName of controlNames">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ ('idCard.'+controlName)|translate }}</mat-label>
                        <input (click)="idFrontUpload.triggerUploadButton()"
                            [value]="idCardFileForm.controls[controlName].value?.name" readonly
                            [formControlName]="controlName" matInput />
                        <itfg-file-upload #idFrontUpload matSuffix [isDisabled]="isUploadingFile[controlName]"
                            [hidden]="uploadedSuccessfully[controlName]"
                            (fileSelected)="onFileSelected($event, controlName)"></itfg-file-upload>
                        <mat-icon matIconSuffix *ngIf="uploadedSuccessfully[controlName]">
                            check_circle
                        </mat-icon>
                        <mat-progress-bar *ngIf="isUploadingFile[controlName]" mode="indeterminate"></mat-progress-bar>
                        <mat-hint *ngIf="!isUploadingFile[controlName] && !uploadedSuccessfully[controlName]">
                            Допустим формат: .jpeg, .gif, .png
                        </mat-hint>
                        <mat-hint *ngIf="uploadedSuccessfully[controlName]">
                            Файлът е качен успешно!
                        </mat-hint>
                        <mat-hint *ngIf="isUploadingFile[controlName]">
                            Моля, изчакайте...
                        </mat-hint>
                        <mat-error>
                            Моля, качете файл в един от следните формати: .jpeg, .gif, .png
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>
        </mat-card-content>
        <mat-card-footer>
            <mat-card-actions>
                <button [disabled]="!idCardFileForm.valid" mat-raised-button color="primary" type="submit">
                    Продължи
                </button>
            </mat-card-actions>
        </mat-card-footer>
    </form>
</div>