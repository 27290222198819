<div class="container">
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form-fields-layout" novalidate>
        <div *ngFor="let field of formFields" class="form-row">
            <itfg-dynamic-form-field [formField]="field" [form]="form"></itfg-dynamic-form-field>
        </div>
        <div *ngIf="formFields.length > 0" class="form-actions">
            <button mat-raised-button color="primary" class="submit-btn to-upper-case" type="submit">
                {{ saveButtonLabel | translate }}
            </button>
        </div>
    </form>
</div>