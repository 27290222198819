<div class="form-layout">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Моля, задайте парола за достъп.
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form novalidate [formGroup]="paswordResetForm" class="form-fields-layout" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                  <mat-label>Парола</mat-label>
                  <input #passwordInput type="password" formControlName="password" matInput placeholder="{{ 'forms.enterPasswordPlaceholder' | translate }}" />
                  <button matSuffix mat-icon-button [itfgTogglePasswordVisibility]="passwordInput" type="button">
                    <mat-icon >visibility</mat-icon>
                  </button>
                  <mat-error *ngIf="paswordResetForm.get('password').hasError('required')">
                      Полето е задължително
                  </mat-error>
                  <mat-error *ngIf="paswordResetForm.get('password').hasError('minlength')">
                      {{ 'forms.validation.minLength' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Потвърди парола</mat-label>
                    <input #confirmedPasswordInput type="password" formControlName="confirmPassword" matInput placeholder="{{ 'forms.enterPasswordPlaceholder' | translate }}" />
                    <button matSuffix mat-icon-button [itfgTogglePasswordVisibility]="confirmedPasswordInput" type="button">
                      <mat-icon >visibility</mat-icon>
                    </button>
                    <mat-error *ngIf="!paswordResetForm.valid">
                      <span *ngIf="paswordResetForm.hasError('confirmedValidator')">
                        {{ 'forms.validation.passwordsMustMatch' | translate }}
                      </span>
                      <span *ngIf="paswordResetForm.get('confirmPassword').hasError('required')">
                        {{ 'forms.validation.required' | translate }}
                      </span>
                    </mat-error>
                  </mat-form-field>
                  <div class="form-actions">
                    <button mat-raised-button color="primary" class="submit-btn to-upper-case" type="submit" [disabled]="!paswordResetForm.valid">
                        {{ "global.confirm" | translate }}
                    </button>
                </div>
          </form>
        <itfg-message [opened]="hasError" label="{{ 'global.error' | translate }}" [sublabel]="errorMessage" type="error"></itfg-message>
        </mat-card-content>
    </mat-card>
</div>