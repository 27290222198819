import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {
  public isChrome: boolean = false;
  public isExplorer: boolean = false;
  public isExplorer_11: boolean = false;
  public isFirefox: boolean = false;
  public isSafari: boolean = false;
  public isOpera: boolean = false;
  public isEdgeDesktop: boolean = false;
  public isEdgeiOS: boolean = false;
  public isEdgeAndroid: boolean = false;
  public isBrave: boolean = false;
  public userAgent: string = '';
  public isIOS: boolean = false;
  public isAndroid: boolean = false;
  public isIpad: boolean = false;
  public isMobile: boolean = false;

  constructor() {    
    this.checkUserAgent();
}

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;
   
    const navigatorBrave: any = window.navigator;
    this.isBrave = navigatorBrave.brave != undefined || navigatorBrave.brave?.isBrave?.name == "isBrave";

    this.isChrome = /chrome/.test(uaString) || /crios/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer_11 = /rv:11/.test(uaString);
    this.isFirefox = /firefox/.test(uaString) || /fxios/.test(uaString);
    this.isSafari = /safari/.test(uaString) && !/chrome/.test(uaString) && !this.isBrave &&  !/crios/.test(uaString) && !/fxios/.test(uaString) && !/opios/.test(uaString);
    this.isOpera = /opr\//.test(uaString) || /opera/.test(uaString) || /opios/.test(uaString) || /opt\//.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);

    this.isIOS = /iphone|ipod|ipad/.test(uaString);
    this.isIpad = /ipad/.test(uaString) || (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
    this.isAndroid = /android/.test(uaString);
    this.isMobile = /mobile/.test(uaString);
  }
}
