<div layout="column">
  <mat-card class="totp-setup-container">
    <mat-card-title>
      <div layout="column"
           layout-align="center"
           flex>
        <div class="logo-container">
          <img src="assets/images/itf.png" />
        </div>
        @if (qrCode) {
        <span>{{ 'twoFactorAuth.mfaSetupTitle' | translate }}</span>
        <span class="mat-mdc-card-subtitle">{{ 'twoFactorAuth.scanQrCodeHint' | translate }}</span>
        <a [routerLink]="" (click)="openTwoFactorSetupInstructions()">Натиснете тук за повече информация.</a>
        } @else {
        <span>{{ 'twoFactorAuth.mfa' | translate }}</span>
        }
      </div>
    </mat-card-title>
    <mat-card-content>
      <div layout="column">
        <div class="mfa-setup-container"
             layout="column"
             *ngIf="qrCode">
          <div layout="row"
               layout-align="center center">
            <span class="scan-qr-code">{{ 'twoFactorAuth.scanQrCode' | translate }}</span>
          </div>
          <div layout="row"
               layout-align="center">
            <img *ngIf="qrCode"
                 [src]="'data:image/jpg;base64,' + qrCode">
          </div>
          <div layout="column"
               layout-align="center"
               class="row-gap-10 copy-code">
            <span class="text-trough-line">{{ 'twoFactorAuth.enterQrCodeManually' | translate }}</span>
            <mat-form-field>
              <input matInput
                     type="text"
                     readonly
                     [(ngModel)]="secret"
                     class="totp-secret">
              @if (secret) {
              <button matSuffix
                      mat-icon-button
                      matTooltip="Копирай"
                      matTooltipPosition="above"
                      [cdkCopyToClipboard]="secret">
                <mat-icon>content_copy</mat-icon>
              </button>
              }
            </mat-form-field>
          </div>
        </div>
        <div layout="column"
             layout-align="center"
             class="content">
          <span class="mat-mdc-card-subtitle">{{ 'twoFactorAuth.enterVerificationCodePrompt' | translate }}</span>
          <itfg-totp-input #input="ngModel"
                           [(ngModel)]="data"
                           (codeSubmitted)="submitTotpCode()"></itfg-totp-input>
          <p><mat-slide-toggle [(ngModel)]="rememberMe">{{ 'twoFactorAuth.rememberMeHint' | translate
              }}</mat-slide-toggle></p>
          <div layout="column" layout-align="center center" class="actions">
            <button #submitCodeBtn
                    mat-raised-button
                    (click)="submitTotpCode()"
                    color="primary"
                    [disabled]="!data || data.length !== 6">{{ 'twoFactorAuth.verify' | translate }}</button>
            <!-- <a *ngIf="!qrCode" [routerLink]="" (click)="cancelTwoFactorVerification()">Анулирай</a> -->
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <itfg-message [opened]="errored"
                label="{{ 'global.error' | translate }}"
                sublabel="{{ 'auth.invalidTotpCode' | translate }}"
                type="error"></itfg-message>
</div>
