export class LoginResponse {
    isSuccessAuthentication: boolean;
    requiresTwoFactorAuth: boolean;
    isInitialSetup?: boolean;
    body: Object = {
        qrCode: null,
        secret: '',
    };

    constructor(partial: LoginResponse) {
        this.isInitialSetup = !!partial?.body?.hasOwnProperty('qrCode');
        this.requiresTwoFactorAuth = partial.requiresTwoFactorAuth;
        this.isSuccessAuthentication = partial.isSuccessAuthentication;
        this.body = (partial.hasOwnProperty('body') && !!partial.body) ? partial.body : {};
    }
}