import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef!: OverlayRef;
  private spinnerOverlayPortal = new ComponentPortal(LoadingIndicatorComponent);

  constructor(private overlay: Overlay) {}

  public show() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }
    if (!this.overlayRef.hasAttached()) {
      const component = this.overlayRef.attach(this.spinnerOverlayPortal);
    }
  }

  public hide() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}