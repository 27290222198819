import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlLocalizedService extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Брой редове на страница: ';
  override nextPageLabel = 'Следваща страница';
  override previousPageLabel = 'Предишна страница';
  override firstPageLabel = 'Начална страница';
  override lastPageLabel = 'Последна страница';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
      return ((page * pageSize) + 1) + ' - ' + ((page * pageSize) + pageSize) + ' от ' + length;
  }
}
