<div class="form-layout">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <div class="header-title-container">
          <div class="logo-container">
            <img [src]="config.logoUrl" />
          </div>
          Вход в профил
        </div>
      </mat-card-title>
    </mat-card-header>
    <form [formGroup]="loginForm" (ngSubmit)="loginClicked()" class="form-fields-layout" novalidate>
      <mat-card-content>
        <mat-form-field subscriptSizing="fixed">
          <mat-label>Имейл</mat-label>
          <input formControlName="email" matInput placeholder="example@gmail.com" />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error *ngIf="loginForm.get('email')?.hasError('required')">Полето е задължително</mat-error>
          <mat-error *ngIf="loginForm.get('email')?.hasError('email')">Невалиден имейл</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="fixed">
          <mat-label>Парола</mat-label>
          <input #passwordInput type="password" formControlName="password" matInput
            placeholder="{{ 'forms.enterPasswordPlaceholder' | translate }}" 
            autocomplete="user-password"/>
          <button matSuffix mat-icon-button [itfgTogglePasswordVisibility]="passwordInput" type="button">
            <mat-icon>visibility</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">Полето е задължително</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <div class="form-actions">
          <button mat-raised-button color="primary" class="submit-btn to-upper-case" type="submit"
            >
            {{ "global.login" | translate }}
          </button>
          <a class="forgotten-password" href="/password-recovery">Забравена паролаа?</a>
        </div>
      </mat-card-actions>
      <itfg-message [opened]="logginErrored" label="{{ 'global.error' | translate }}" sublabel="{{ 'errors.badCredentialsException' | translate }}" type="error"></itfg-message>
    </form>
  </mat-card>
</div>