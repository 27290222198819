import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  isDevMode,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

function getFormArray(size: number): FormArray {
  const arr = [];

  for (let i = 0; i < size; i++) {
    arr.push(new FormControl(''));
  }

  return new FormArray(arr);
}

@Component({
  selector: 'itfg-totp-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TotpInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TotpInputComponent,
      multi: true,
    },
  ],
  templateUrl: './totp-input.component.html',
  styleUrls: ['./totp-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotpInputComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @Input()
  set size(size: number) {
    this.inputs = getFormArray(size);
    this.#size = size;
  }

  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;

  ngAfterViewInit() {
    // Set focus to the first input element after the view has initialized
    if (this.inputEls && this.inputEls.first) {
      this.inputEls.first.nativeElement.focus();
    }
  }

  #size = 6;
  #scheduledFocus: number = null;

  inputs = getFormArray(this.#size);
  @Output() codeSubmitted = new EventEmitter<any>();

  onChange?: (value: string) => void;
  onTouched?: () => void;

  writeValue(value: string): void {
    if (isDevMode() && value?.length) {
      throw new Error('Otp input is not supposed to be prefilled with data');
    }

    // Reset all input values
    this.inputs.setValue(new Array(this.#size).fill(''));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputs.disable();
    } else {
      this.inputs.enable();
    }
  }

  validate(control: AbstractControl<string, string>): ValidationErrors | null {
    if (!control.value || control.value.length < this.#size) {
      return {
        otpInput: 'Value is incorrect',
      };
    }
    return null;
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (idx > 0) {
        this.#scheduledFocus = idx - 1;
        this.handleInput();
      }
    }
  }

  handleInput() {
    this.#updateWiredValue();

    if (this.#scheduledFocus != null) {
      this.#focusInput(this.#scheduledFocus);
      this.#scheduledFocus = null;
    }
  }

  handleKeyPress(e: KeyboardEvent, idx: number) {
    const isDigit = /\d/.test(e.key);

    if (e.key === 'v' && e.metaKey) {
      return true;
    }

    if (isDigit && idx + 1 < this.#size) {
      this.#scheduledFocus = idx + 1;
    }

    if (isDigit && this.inputs.controls[idx].value) {
      this.inputs.controls[idx].setValue('');
    }
    return isDigit;
  }

  handlePaste(e: ClipboardEvent, idx: number) {
    e.preventDefault();

    if (idx !== 0) {
      return;
    }

    const pasteData = e.clipboardData?.getData('text');
    const regex = new RegExp(`\\d{${this.#size}}`);

    if (!pasteData || !regex.test(pasteData)) {
      return;
    }

    for (let i = 0; i < pasteData.length; i++) {
      this.inputs.controls[i].setValue(pasteData[i]);
    }

    this.#focusInput(this.inputEls.length - 1);
    this.#updateWiredValue();
    this.onTouched();
  }

  handleFocus(e: FocusEvent) {
    (e.target as HTMLInputElement).select();
  }

  #focusInput(idx: number) {
    setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
  }

  #updateWiredValue() {
    setTimeout(() => this.onChange?.(this.inputs.value.join('')));
  }

  submitForm() {
    this.codeSubmitted.emit(true);
  }

}
