<mat-card>
    <mat-card-header>
        <mat-card-title>
            <div>
                <div>Грешка</div>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- За повече информация, моля свържете се с нас. -->
        {{ rawError.error.message || rawError.message }}
        <!-- За повече информация, моля <a class="no-href-style" href="">свържете се с нас.</a> -->
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-stroked-button type="button" (click)="onClose()">
            {{ 'global.close' | translate }}
        </button>
    </mat-card-actions>
</mat-card>