<div class="form-layout">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Моля, въведете вашият имейл адрес.
      </mat-card-title>
    </mat-card-header>
    <form novalidate [formGroup]="emailForm" class="form-fields-layout" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Имейл</mat-label>
          <input formControlName="email" matInput placeholder="example@gmail.com" />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error *ngIf="emailForm.get('email')?.hasError('required')">Полето е задължително</mat-error>
          <mat-error *ngIf="emailForm.get('email')?.hasError('email')">Невалиден имейл</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" class="submit-btn to-upper-case" type="submit"
            [disabled]="!emailForm.valid">
            {{ "global.proceed" | translate }}
          </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>