import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ApplicationCreateFormFieldsService } from 'src/app/components/shared/dynamic-form/application-create-form-fields.service';
import { FormFieldControlService } from 'src/app/components/shared/dynamic-form/form-field-control.service';
import { ApplicationFormFieldType, CreateApplicationDto, ApplicationFormFieldType as FieldType } from 'src/app/types/application.type';
import { CustomFormFieldBase } from 'src/app/components/shared/dynamic-form/dynamic-form-control-types/custom-form-field.type';
import { NotificationService } from 'src/app/services/notification.service';
import { UserDocumentType } from 'src/app/types/user.type';

export const identityDocumentsRequiredValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const ID_FRONT = control.get(UserDocumentType.ID_FRONT);
  const ID_BACK = control.get(UserDocumentType.ID_BACK);
  const SELFIE = control.get(UserDocumentType.SELFIE);
  const allControls = [ID_FRONT, ID_BACK, SELFIE];
  const someHaveValue = allControls.some(control => !!control.value);
  const allHaveValue = allControls.every(control => !!control.value);
  const isRequired = someHaveValue && !allHaveValue;
  let error: any = null;
  if (isRequired) {
    error = { allDocumentsMustBePresent: true };
  } else if (!someHaveValue || allHaveValue) {
    error = null;
  }
  allControls.forEach(control => {
    if (!control.value) {
      control.setErrors(error);
      control.markAsTouched();
    }
  });
  return error;
};

@Component({
  selector: 'itfg-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() fields: FieldType[] = [];
  @Input() formFields: CustomFormFieldBase<string>[] | null = [];
  @Input() saveButtonLabel?: string = 'global.save';
  form!: FormGroup;
  @Output() formSubmitted = new EventEmitter<CreateApplicationDto>();

  constructor(
    private formFieldControlService: FormFieldControlService,
    private applicationCreateFormFieldsService: ApplicationCreateFormFieldsService,
    private notifications: NotificationService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('fields')) {
      this.loadFormFields();
    }
  }

  ngOnInit(): void {
    this.form = this.formFieldControlService.toFormGroup(
      this.formFields as CustomFormFieldBase<string>[]
    );
    const docTypes = [UserDocumentType.ID_BACK, UserDocumentType.ID_FRONT, UserDocumentType.SELFIE];
    const allDocumentTypesIncluded = docTypes.every(docType => this.formFields.map(field => field.key).includes(docType));
    if (allDocumentTypesIncluded) {
      this.form.setValidators(identityDocumentsRequiredValidator);
    }
  }

  loadFormFields() {
    this.applicationCreateFormFieldsService.getFormFields$(this.fields).subscribe(formFields => {
      this.formFields = formFields;
    });
  }

  generatePayload() {
    let payload: any = {};
    const rawForm = this.form.getRawValue();
    for (let key of Object.keys(rawForm)) {
      const foundFormField = this.formFields.find(field => field.key === key);
      if (foundFormField
        && foundFormField.hasOwnProperty('payloadParserCallback')
        && typeof foundFormField.payloadParserCallback === 'function'
      ) {
        const parsedPayload = foundFormField.payloadParserCallback(rawForm[key]);
        if (typeof parsedPayload === 'object'
          && !Array.isArray(parsedPayload)
          && parsedPayload !== null) {
          payload = {
            ...payload,
            ...foundFormField.payloadParserCallback(rawForm[key]),
          }
        } else {
          payload[key] = parsedPayload;
        }
      } else {
        payload[key] = rawForm[key];
      }
    }

    return payload;
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'global.formInvalid',
      });
    } else {
      let payload: any = this.generatePayload();
      this.formSubmitted.emit(payload);
    }

  }

}
