import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../shared/error-dialog/error-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { PasswordValidator } from 'src/app/validation/itfg-validation';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'itfg-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit {
  public passwordResetToken: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private notifications: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const { emailConfirmationToken, passwordResetToken, hashedEmail } =
      this.route.snapshot.params;
    this.auth.emailConfirmation$(emailConfirmationToken).subscribe({
      next: () => {
        this.notifications.showLocalizedSuccessMessage({
          notificationText: 'profile.emailConfirmSuccess',
        });
        if (!passwordResetToken) {
          this.router.navigate(['auth', 'login']);
        } else {
          this.router.navigate(['password-recovery', hashedEmail, passwordResetToken]);
        }
      },
      error: (errorResponse: HttpErrorResponse) => {
        if (
          errorResponse.error.exception ===
          'com.itfgroup.client.profile.error.InvalidTokenException'
        ) {
          // this.notifications.showLocalizedSuccessMessage({
          //   notificationText: 'profile.emailConfirmationTokenInvalid',
          // });
          this.router.navigate(['auth', 'login']);
        }
      },
    });
  }
}
