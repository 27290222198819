import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserLoginDto } from 'src/app/types/user.type';
import { Location } from '@angular/common';
import { ExceptionType } from 'src/app/types/exceptions.type';
import { HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG, AppConfig, DOMAIN_CONFIG_TOKEN } from 'src/app/config/app.config';
import { LoginResponse } from 'src/app/types/login-response';

@Component({
  selector: 'itfg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public loginErrored: boolean = false;
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  public stateEmail: string;
  public logginErrored: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private notifications: NotificationService,
    private location: Location,
    @Inject(DOMAIN_CONFIG_TOKEN)
    public config: AppConfig,
  ) {
    const state: any = location.getState();
    this.stateEmail = state['email'];
    this.loginForm = this.createLoginForm();
  }

  createLoginForm() {
    // const defaultEmail = this.router.getCurrentNavigation().extras.state['email'];
    console.log(this.stateEmail)
    return this.fb.group({
      email: [
        this.stateEmail || '',
        [Validators.required, Validators.email],
      ],
      password: ['', [Validators.required]],
    });
  }

  loginClicked() {
    if (this.loginForm.valid) {
      const loginDto = new UserLoginDto({
        identity: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,  
      });
      this.authService
        .login$(loginDto)
        .subscribe({
          next: this.onSuccessfullLogin.bind(this),
          error: this.handleLoginFailure.bind(this),
        });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'global.formInvalid'
      });
    }
  }

  handleLoginFailure(errorResponse: HttpErrorResponse) {
    if (errorResponse.error.exception === ExceptionType.BAD_CREDENTIALS) {
      this.logginErrored = true;
    }
  }

  onSuccessfullLogin(response: LoginResponse) {
    console.log(response);

    if (response.isSuccessAuthentication) {
      this.logginErrored = false;

      if (response.isInitialSetup) {
        return this.router.navigate(['auth', '2fa'], {state: { ...response.body } });
      }

      if (response.requiresTwoFactorAuth) {
        return this.router.navigate(['auth', '2fa']);
      }
      
      return this.router.navigate(['/home']);
    } else {
      return this.loginErrored = true;
    }
  }
}
