import { Component } from '@angular/core';

@Component({
  selector: 'itfg-pep-disclaimer',
  templateUrl: './pep-disclaimer.component.html',
  styleUrls: ['./pep-disclaimer.component.scss'],
})
export class PepDisclaimerComponent {

  constructor(
  ) { }

 

}
