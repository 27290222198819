<!-- iOS mobile devices -->
<div [ngClass]="{
    'ios-instructions-prompt': displayPrompt === deviceTypes.iOSMobileSafari,
    'ios-ipad-instructions-prompt': displayPrompt === deviceTypes.iosIpadSafari
  }" *ngIf="displayPrompt === deviceTypes.iOSMobileSafari || displayPrompt === deviceTypes.iosIpadSafari">
    <div class="instructions-container">
        <button mat-icon-button (click)="closePrompt()" class="close-prompt-btn">
            <mat-icon>close</mat-icon>
        </button>
        <p class="main-text">
            {{ 'Инсталирайте ' + (config.name === 'Medifin' ? ('global.medifin' | translate) : ('global.itfgPartners' | translate)) + ' на началния си екран!' }}
        </p>
        <div class="share-icon-instructions">
            <span>Кликнете върху</span>
            <img src="../../../assets/icons/iphone-share-icon.png" alt="Iphone share icon" class="iphone-share-icon">
            <span>иконката</span>
        </div>
        <div class="add-to-home-instructions">
            <p>Превъртете надолу и щракнете върху:</p>
            <div class="add-to-home-action">
                <span>Add to Home Screen</span>
                <mat-icon>add_box</mat-icon>
            </div>
            <button mat-button (click)="doNotShowAgain()" class="do-not-show-btn">
                Не показвай отново
            </button>
        </div>
        <svg *ngIf="displayPrompt === deviceTypes.iOSMobileSafari" stroke="currentColor" fill="currentColor"
            stroke-width="0" viewBox="0 0 16 16" class="arrow-down" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15.5l7.5-7.5h-4.5v-8h-6v8h-4.5z"></path>
        </svg>
        <svg *ngIf="displayPrompt === deviceTypes.iosIpadSafari" stroke="currentColor" fill="currentColor"
            stroke-width="0" viewBox="0 0 16 16" class="arrow-up" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.5l-7.5 7.5h4.5v8h6v-8h4.5z"></path>
        </svg>
    </div>
</div>

<!-- Desktop and Android mobile devices -->
<div *ngIf="deferredPrompt && (displayPrompt === deviceTypes.AndroidMobile || displayPrompt === deviceTypes.Desktop)">
    <button mat-button (click)="addToHomeScreen()" class="install-btn">
        <mat-icon>get_app</mat-icon>
        <span>{{ 'global.install' | translate }}</span>
    </button>
</div>