import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Data } from '@angular/router';
import { BehaviorSubject, Observable, filter, finalize, iif, map, merge, mergeMap, of, tap, throwError } from 'rxjs';
import { CreditsService } from 'src/app/services/credits.service';
import { ApplicationFormFieldType, ApplicationParsedView, CreditStatusNames } from 'src/app/types/application.type';
import { CreditDocument } from 'src/app/types/credit.type';
import { UserDocumentType } from 'src/app/types/user.type';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { PromptDialogComponent } from '../../shared/prompt-dialog/prompt-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatStepperIconContext } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

enum CreditConfirmationSteps {
  ID_UPLOAD = 'ID_ULOAD',
  CONFIRM_CREDIT = 'CONFIRM_CREDIT',
  DONE = 'DONE',
}

@Component({
  selector: 'itfg-credit-confirmation-public-view',
  templateUrl: './credit-confirmation-public-view.component.html',
  styleUrls: ['./credit-confirmation-public-view.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CreditConfirmationPublicViewComponent implements OnInit, AfterViewInit, AfterContentInit {
  public application: ApplicationParsedView;
  public documents: CreditDocument[];
  public creditId: number;
  public token: string;
  public displayError: boolean = false;
  public errorMessage: string = '';
  acceptedAgreement = true;
  @ViewChild('matVerticalStepper', { static: false })
  stepper: MatStepper;
  includedFields: ApplicationFormFieldType[] = [
    ApplicationFormFieldType.ID_FRONT,
    ApplicationFormFieldType.ID_BACK,
    ApplicationFormFieldType.SELFIE,
  ];
  stepsTypes = CreditConfirmationSteps;
  public stepsState$: Observable<{ [key: string]: any }>;
  stepsSubscription: any;
  private stepsState = new BehaviorSubject<{ [key: string]: any }>({
    [CreditConfirmationSteps.ID_UPLOAD]: {
      stepIndex: 0,
      hasError: false,
      errorMessage: '',
      label: 'Верификация на самоличност',
    },
    [CreditConfirmationSteps.CONFIRM_CREDIT]: {
      stepIndex: 1,
      hasError: false,
    },
    [CreditConfirmationSteps.DONE]: {
      stepIndex: 2,
      hasError: false,
    },
  });
  refreshBtnDisabled = false;
  firstFormGroup: FormGroup = this._formBuilder.group({ value: [null, [Validators.required]] });
  secondFormGroup: FormGroup = this._formBuilder.group({ value: [null, [Validators.required]] });
  thirdFormGroup: FormGroup = this._formBuilder.group({ value: [null, [Validators.required]] });

  public settings = {
    documentsShown: false,
    uploadDocumentsShown: false,
  }

  constructor(
    private credits: CreditsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notifications: NotificationService,
    private _formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const { creditId, token } = this.route.snapshot.params;
    this.creditId = Number(creditId);
    this.token = token;
    this.loadApplicationFromResolver();
    this.stepsState$ = this.stepsState.asObservable();
  }

  updateStepState(stepKey: string, newState: any) {
    const currentState = this.stepsState.value;
    const updatedSteps = {
      ...currentState,
      [stepKey]: {
        ...currentState[stepKey],
        ...newState,
      },
    };
    this.stepsState.next(updatedSteps);
  }

  ngAfterViewInit() {
    this.configureSteps();
  }

  ngAfterContentInit(): void {
    this.configureSteps();
  }

  goToStep(nextStepIdx: number) {
    this.stepper.steps.get(nextStepIdx).editable = true;
    this.stepper.selectedIndex = nextStepIdx;
    // Disable previous steps to prevent going back in steps
    this.stepper.steps.forEach((step, index) => {
      if (index !== nextStepIdx) {
        step.editable = false;
      }
    });
  }

  configureSteps() {
    if (!this.stepper) {
      return;
    }

    if (this.application && !this.application.inStatus(CreditStatusNames.APPROVED)) {
      // Skips the first two steps and jump staight to done
      this.firstFormGroup.patchValue({ value: true });
      this.goToStep(1);
      this.secondFormGroup.patchValue({ value: true });
      this.goToStep(2);
      this.thirdFormGroup.patchValue({ value: true });
      this.cdRef.detectChanges();
      return;
    }

    if (this.application.verification.PROCESSING) {
      this.updateStepState(CreditConfirmationSteps.ID_UPLOAD, {
        hasError: true,
        label: 'Личната ви карта е в процес на валидация.',
        errorMessage: 'Моля, изчакайте.',
      });
      // this.firstFormGroup.patchValue({ value: true });
      // this.goToStep(1);
      return;
    }

    if (!!this.application.verification.VALID) {
      this.updateStepState(CreditConfirmationSteps.ID_UPLOAD, {
        hasError: false,
        label: 'Личната Ви карта е валидирана успешно!',
      });
      this.firstFormGroup.patchValue({ value: true });
      this.goToStep(1);
      return;
    }

    if (!this.application.verification.VALID) {
      this.updateStepState(CreditConfirmationSteps.ID_UPLOAD, {
        hasError: false,
        label: 'Верификация на самоличност'
      });
      this.firstFormGroup.patchValue({ value: null });
      this.goToStep(0);
      return;
    }

  }

  loadApplicationFromResolver() {
    this.route.data.pipe(
      tap((routeData: Data) => {
        console.log(routeData);
        const confirmationData = routeData['creditConfirmationData'];
        const exception = confirmationData['exception'];
        if (confirmationData.hasOwnProperty('exception')) {
          this.displayError = true;
          this.errorMessage = exception.error?.message;
        } else {
          this.application = confirmationData;
          this.configureSettings(this.application);
        }
      }),
      mergeMap(this.loadDocumentsPublic$.bind(this))
    ).subscribe((fetchedDocs: CreditDocument[]) => this.documents = fetchedDocs);
  }

  resolveRefreshApliication() {
    this.refreshBtnDisabled = true;
    this.refreshApplication$().pipe(
      finalize(() => this.refreshBtnDisabled = false)
    ).subscribe();
  }

  refreshApplication$() {
    return this.credits.fetchApplicationPublic$(this.creditId, this.token)
      .pipe(
        tap((application: ApplicationParsedView) => {
          this.application = application;
          this.configureSettings(application);
        }),
        mergeMap(this.loadDocumentsPublic$.bind(this))
      );
  }

  configureSettings(application: ApplicationParsedView) {
    this.settings.documentsShown = false;
    this.settings.uploadDocumentsShown = false;

    if (!application.raw.credit) {
      return null;
    }

    if (application.verification.VALID) {
      this.settings.documentsShown = true;
    } else if (application.verification.PROCESSING) {
      this.settings.documentsShown = true;
    } else {
      this.settings.uploadDocumentsShown = true;
    }

    this.configureSteps();
    return this.settings;
  }

  openIdVerificationProcessingDialog(): Observable<MatDialogRef<InfoDialogComponent>> {
    return this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Личната Ви карта е в процес на валидация.',
        message: 'Моля изчакайте.',
      }
    }).afterClosed();
  }

  loadApplicationPublic$(): Observable<ApplicationParsedView> {
    return this.credits.fetchApplicationPublic$(this.creditId, this.token)
      .pipe(
        tap((response: ApplicationParsedView) => {
          if (response && response.raw.credit) {
            this.application = response;
            this.displayError = false;
          } else {
            this.displayError = true;
            this.errorMessage = 'Невалиден кредит';
          }
        }),
      );
  }

  loadDocumentsPublic$(): Observable<CreditDocument[]> {
    return iif(
      () => this.settings.documentsShown,
      this.credits.fetchCreditDocumentsPublic$(this.creditId, this.token).pipe(
        map((documents: CreditDocument[]) => documents.filter(document => {
          const hiddenDocumentCodes = ['FRAME_AGREEMENT_ATTACHMENT_2', 'FRAME_AGREEMENT_ATTACHMENT_1'];
          return !hiddenDocumentCodes.includes(document.templateCode);
        })),
        tap((response: CreditDocument[]) => this.documents = response),
      ),
      of(null)
    )
  }

  onDocumentsSubmit(files: any) {
    const requiredDocTypes = [UserDocumentType.SELFIE, UserDocumentType.ID_BACK, UserDocumentType.ID_FRONT];
    const allFilesUploaded = requiredDocTypes.every(f => !!files[f]);
    if (allFilesUploaded) {
      this.uploadIdCardDocumentsPublicSync$(files)
        .pipe(
          mergeMap(() => this.refreshApplication$())
        )
        .subscribe({
          next: () => {
            this.dialog.open(InfoDialogComponent, {
              data: {
                title: 'Документите са качени успешно!',
                message: 'Личната ви карта е в процес на валидация.',
              }
            }).afterClosed();
          },
          error: this.notifications.handleHttpErrors.bind(this.notifications),
        });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'Моля, качете всички нужни файлове.',
      });
    }
  }

  private uploadIdCardDocumentsPublicSync$(files: { [key in UserDocumentType]: File }): Observable<any> {
    const _uploadObservable = (type: UserDocumentType, file: File) => {
      return () => this.uploadIdCardDocumentPublic$({
        creditId: this.application.raw.credit.id,
        fileToUpload: file,
        documentType: type
      });
    }
    return of([]).pipe(
      mergeMap(_uploadObservable(UserDocumentType.ID_FRONT, files[UserDocumentType.ID_FRONT])),
      mergeMap(_uploadObservable(UserDocumentType.ID_BACK, files[UserDocumentType.ID_BACK])),
      mergeMap(_uploadObservable(UserDocumentType.SELFIE, files[UserDocumentType.SELFIE])),
    );
  }

  private uploadIdCardDocumentPublic$(args: {
    creditId: number;
    fileToUpload: File;
    documentType: UserDocumentType;
  }): Observable<any> {
    const { creditId, fileToUpload, documentType } = args;
    const formData: FormData[] = [];
    formData.push(new FormData());
    formData[0].append('document', fileToUpload);
    const formDataElement = formData[0];
    return this.credits.uploadCreditDocumentByTypePublic$(
      creditId,
      documentType,
      formDataElement,
      this.token
    );
  }

  confirmClicked() {
    this.refreshApplication$().subscribe(() => {
      if (this.application.verification.VALID) {
        this.dialog.open(PromptDialogComponent, {
          data: {
            title: '<span class="primary">Потвърждаване на договор</span>',
            message: 'Сигурни ли сте, че искате да потвърдите договора?',
            panelClass: 'primary',
          },
          maxWidth: '450px'
        })
          .afterClosed()
          .pipe(
            filter((accepted: boolean) => accepted),
            mergeMap(() => this.credits.confirmApplicationPublic$(this.application.raw.credit.id, this.token)),
            mergeMap(() => this.refreshApplication$())
          )
          .subscribe({
            next: () => this.notifications.showLocalizedSuccessMessage({
              notificationText: 'Договора е потвърден успешно!'
            }),
            error: this.notifications.handleHttpErrors.bind(this.notifications),
          });
      } else if (this.application.verification.PROCESSING) {
        this.openIdVerificationProcessingDialog().subscribe(() => { });
      }
    })
  }

  rejectClicked() {
    this.dialog.open(PromptDialogComponent, {
      data: {
        title: '<span class="warn">Отказ от договор</span>',
        message: 'Сигурни ли сте, че искате да откажете договора?',
        panelClass: 'warn',
      }
    })
      .afterClosed()
      .pipe(
        filter((accepted: boolean) => accepted),
        mergeMap(() => this.credits.rejectApplicationPublic$(this.application.raw.credit.id, this.token)),
        mergeMap(() => this.refreshApplication$())
      )
      .subscribe({
        next: () =>
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'Договора е отказан успешно!'
          }),
        error: this.notifications.handleHttpErrors.bind(this.notifications),
      });
  }

  documentClicked(selectedDocument: CreditDocument) {
    this.credits.getCreditDocumentPdfPublic$(this.application.id, selectedDocument.id, this.token).subscribe({
      next: (data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = selectedDocument.templateName + '.pdf';
        document.body.appendChild(a);
        a.click();
      },
      error: (error) => {
        console.log('getPDF error: ', error);
      }
    });
  }

  ngOnDestroy() {
    if (this.stepsSubscription) {
      this.stepsSubscription.unsubscribe();
    }
  }

}
