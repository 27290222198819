import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, finalize, tap } from 'rxjs';
import { WebHttpUrlEncodingCodec } from '../utils/webHttpUrlEncodingCodec';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class ClientCommunicationRequestService extends RequestService {

  constructor(http: HttpClient) {
    super(http);
    this.apiUrl = environment.CLIENT_COMMUNICATION_API_URL;
  }


}
